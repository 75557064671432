body {
  font-family: "Archivo", sans-serif;
  font-weight: 200;
  margin: 0px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  line-height: 22px;
}

a {
  text-decoration: none;
  color: black;
}

.wrapper {
  display: flex;
  flex-direction: row;
  padding: 32px;
  position: relative;
}

.container.header {
  position: sticky;
  left: 0;
  top: 0;
  height: 75vh;
  min-width: 250px;
}

.container.homepage {
  width: 100%;
}

.header-contents {
  display: flex;
  flex-direction: column;
}

.header-link.name {
  margin-bottom: 20px;
  white-space: nowrap;
}

.header-link.name:hover {
  color: black;
}

.bold {
  font-weight: 400;
}

.collections {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.painting {
  width: 200px;
  margin: 10px;
}

.f-thumbs__viewport {
  display: none;
}

.fancybox__toolbar__column.is-left {
  display: none;
}

div.fancybox__toolbar__column.is-right > :nth-child(-n + 4) {
  display: none;
}

.header-list {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.hamburger-icon {
  all: unset;
  display: none;
}

.hamburger-icon:focus {
  outline: revert;
}

.hamburger-icon:hover {
  cursor: pointer;
}

.close-icon {
  all: unset;
  display: none;
}

.close-icon:focus {
  outline: revert;
}

.close-icon:hover {
  cursor: pointer;
}

.homepage-pic {
  width: 100%;
  max-width: 700px;
  margin-top: 10px;
}

.scroll-to-top {
  display: none !important;
}

.collection-wrapper div {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-width: 900px;
}

a:hover {
  color: grey;
}

.contact-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 10px;
}

.about-text {
  margin: 10px;
}

.four-container {

}

@media screen and (max-width: 600px) {
  @keyframes dropDownWrapper {
    0% {
      top: 0px;
      transform: translateY(0);
    }
    100% {
      top: 290px;
      transform: translateY(0);
    }
  }

  @keyframes dropDown {
    0% {
      top: -200px;
      transform: translateY(0);
    }
    100% {
      top: -288px;
      transform: translateY(0);
    }
  }

  .header-list {
    display: none;
  }

  .mobile-nav {
    display: none;
  }

  .mobile-nav.open {
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: white;
    top: 0px;
    left: 0px;
    width: 100%;
    animation: dropDown 0.3s forwards;
  }

  .wrapper.nav-open {
    animation: dropDownWrapper 0.3s forwards;
  }

  .mobile-link {
    color: black;
    padding: 20px 30px;
  }

  .hamburger-icon {
    display: block;
    width: 20px;
    padding-bottom: 20px;
    padding-left: 40px;
  }

  .close-icon {
    display: block;
    width: 20px;
    padding-bottom: 20px;
    padding-left: 40px;
  }

  .header-contents {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .container.header {
    position: unset !important;
    width: 100%;
  }

  .wrapper {
    flex-direction: column;
    animation: dropDownWrapperReverse 0.4s forwards;
  }

  .header {
    height: 20vh !important;
  }

  .painting {
    width: 100%;
    margin: 0px;
    margin-bottom: 30px;
  }

  .about-text {
    margin: 0px;
  }

  .contact-wrapper {
    margin: 0px;
  }

  .painting.series {
    margin-bottom: 10px;
  }

  .homepage-pic {
    margin: 0px;
  }

  .f-button[title^="Close"] {
    display: flex !important;
  }

  .f-button.is-next {
    display: none !important;
  }

  .f-button.is-prev {
    display: none !important;
  }

  .scroll-to-top {
    display: flex !important;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
  }
  
  .scroll-to-top svg {
    width: 20px;
    padding: 10px 40px;
  }

  .scroll-to-top p:hover {
    color: grey;
    cursor: pointer;
  }

  .exhibition-text {
    padding-left: 20px;
    padding-right: 20px;
  }
}
